export default [
  // {
  //   header: 'Pagine',
  // },
  {
    title: 'Utenti',
    route: 'apps-users-list',
    icon: 'UserIcon',
    action: 'read',
    resource: 'Users',
  },
  {
    title: 'Contatti',
    route: 'apps-contacts-list',
    icon: 'BookIcon',
    action: 'read',
    resource: 'Contacts',
  },
  {
    title: 'Sedi',
    route: 'apps-headquarters-list',
    icon: 'MapPinIcon',
    action: 'read',
    resource: 'Headquarters',
  },
  {
    title: 'Reparti',
    route: 'apps-departments-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Departments',
  },
  {
    title: 'Sale Riunioni',
    route: 'apps-meetingrooms-list',
    icon: 'MonitorIcon',
    action: 'read',
    resource: 'Meetingrooms',
  },
  {
    title: 'Sale Riunioni Reception',
    route: 'apps-meetingroomsreception-list',
    icon: 'MonitorIcon',
    action: 'read',
    resource: 'MeetingroomsReception',
  },
  {
    title: 'Veicoli',
    route: 'apps-vehicles-list',
    icon: 'TruckIcon',
    action: 'read',
    resource: 'Vehicles',
  },
  {
    title: 'Veicoli Reception',
    route: 'apps-vehiclesreception-list',
    icon: 'TruckIcon',
    action: 'read',
    resource: 'VehiclesReception',
  },
  {
    title: 'Consegne Divisa',
    route: 'apps-uniformsreception-list',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'UniformsReception',
  },
  {
    title: 'Proposte Ferie',
    route: 'apps-justificationproposals-list',
    icon: 'CompassIcon',
    action: 'read',
    resource: 'Justificationproposals',
  },
  // {
  //   title: 'Proposte Ferie',
  //   route: 'apps-holidayproposals-list',
  //   icon: 'CompassIcon',
  //   action: 'read',
  //   resource: 'Holidayproposals',
  // },
  {
    title: 'Calendario',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'Calendar',
  },
  {
    title: 'Calendario Reception',
    route: 'apps-calendarreception',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'CalendarReception',
  },
  {
    title: 'Calendari Utente',
    route: 'apps-calendarusers-list',
    icon: 'LinkIcon',
    action: 'read',
    resource: 'Calendarusers',
  },
  {
    title: 'Timbrature',
    route: 'apps-worktimes-list',
    icon: 'ClockIcon',
    action: 'read',
    resource: 'Worktimes',
  },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  {
    title: 'Comunicazioni',
    route: 'apps-conversations',
    icon: 'MessageSquareIcon',
    action: 'read',
    resource: 'Conversations',
  },
  {
    title: 'Comunicazioni Reception',
    route: 'apps-conversationsreception',
    icon: 'MessageSquareIcon',
    action: 'read',
    resource: 'ConversationsReception',
  },
  {
    title: 'Richieste Ferie',
    route: 'apps-justifications-list',
    icon: 'ClipboardIcon',
    action: 'read',
    resource: 'Justifications',
  },
  {
    title: 'Richieste Ferie Utenti',
    route: 'apps-managerjustifications-list',
    icon: 'ClipboardIcon',
    action: 'read',
    resource: 'ManagerJustifications',
  },
  {
    title: 'Proposte Ferie Utenti',
    route: 'apps-managerjustificationproposals-list',
    icon: 'CompassIcon',
    action: 'read',
    resource: 'ManagerJustificationproposals',
  },
  {
    title: 'Prospetto Ferie',
    route: 'apps-managerjustificationsschedule-list',
    icon: 'ListIcon',
    action: 'read',
    resource: 'ManagerJustificationsSchedule',
  },
  {
    title: 'Calendario Ferie',
    route: 'apps-managercalendar',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'ManagerCalendar',
  },
  {
    title: 'Richieste Divisa',
    route: 'apps-manageruniforms-list',
    icon: 'ShoppingBagIcon',
    action: 'read',
    resource: 'ManagerUniforms',
  },
  {
    title: 'Note Spesa',
    route: 'apps-expensenotes-list',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'Expensenotes',
  },
  {
    title: 'Viaggi',
    route: 'apps-businesstrips-list',
    icon: 'GlobeIcon',
    action: 'read',
    resource: 'Businesstrips',
  },
  // {
  //   title: 'Compiti',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   title: 'Fatture',
  //   icon: 'FileTextIcon',
  //   children: [
  //     {
  //       title: 'Elenco',
  //       route: 'apps-invoice-list',
  //     },
  //     {
  //       title: 'Anteprima',
  //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Modifica',
  //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //     },
  //     {
  //       title: 'Aggiungi',
  //       route: { name: 'apps-invoice-add' },
  //     },
  //   ],
  // },

  // {
  //   title: 'Pages',
  //   icon: 'FileIcon',
  //   children: [
  //     {
  //       title: 'Authentication',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Login v1',
  //           route: 'auth-login-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Login v2',
  //           route: 'auth-login-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v1',
  //           route: 'auth-register-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Register v2',
  //           route: 'auth-register-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v1',
  //           route: 'auth-forgot-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Forgot Password v2',
  //           route: 'auth-forgot-password-v2',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v1',
  //           route: 'auth-reset-password-v1',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Reset Password v2',
  //           route: 'auth-reset-password-v2',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Impostazioni Account',
  //       route: 'pages-account-setting',
  //     },
  //     {
  //       title: 'Profile',
  //       route: 'pages-profile',
  //     },
  //     {
  //       title: 'Faq',
  //       route: 'pages-faq',
  //     },
  //     {
  //       title: 'Knowledge Base',
  //       route: 'pages-knowledge-base',
  //     },
  //     {
  //       title: 'Pricing',
  //       route: 'pages-pricing',
  //     },
  //     {
  //       title: 'Blog',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'pages-blog-list',
  //         },
  //         {
  //           title: 'Detail',
  //           route: { name: 'pages-blog-detail', params: { id: 1 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'pages-blog-edit', params: { id: 1 } },
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Mail Templates',
  //       children: [
  //         {
  //           title: 'Welcome',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
  //         },
  //         {
  //           title: 'Reset Password',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
  //         },
  //         {
  //           title: 'Verify Email',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
  //         },
  //         {
  //           title: 'Deactivate Account',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
  //         },
  //         {
  //           title: 'Invoice',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
  //         },
  //         {
  //           title: 'Promotional',
  //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
  //         },
  //       ],
  //     },
  //     {
  //       title: 'Miscellaneous',
  //       icon: 'CircleIcon',
  //       children: [
  //         {
  //           title: 'Coming Soon',
  //           route: 'misc-coming-soon',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Not Authorized',
  //           route: 'misc-not-authorized',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Under Maintenance',
  //           route: 'misc-under-maintenance',
  //           target: '_blank',
  //         },
  //         {
  //           title: 'Error',
  //           route: 'misc-error',
  //           target: '_blank',
  //         },
  //       ],
  //     },
  //   ],
  // },
]
